<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/interestpage/interestlist')" class="el-icon-close"></i>
      </div>

      <div class="pt20"></div>
    <div class="form">
      <el-form ref="form" :required="true" :model="form" label-width="180px">
          <el-form-item :required="true" label="名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model="form.sort"></el-input>
          </el-form-item>
            <el-upload
                class="upload-demo"
                style="margin-left: 180px;"
                action="#"
                :http-request="httpRequest"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                multiple
                :limit="limit"
                :on-exceed="handleExceed"
                :before-upload="beforeAvatarUpload"
                :file-list="fileList">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2MB</div>
            </el-upload>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">添加</el-button>
          <el-button @click="goNav('/interestpage/interestlist')" >取消</el-button>
        </el-form-item>
      </el-form>
      
    </div>
      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src 
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      form: {
          sort:'',
          type:'',
          name: '',
          url: '',
          img_url:'',
        },
        limit:1,
        options:[],
        fileList: []
      
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
      httpRequest(data) {
            let file = data.file;
            const isImg = file.size / 1024 / 1024 < 2
            if (!isImg) {
                 return 
            }
            const isType = file.type === "image/png"
            const isType2 = file.type === "image/jpeg"
            if (!isType && !isType2) {
                 return 
            }
          let _this = this;
            console.log(file)
        let formdata = new FormData();
        formdata.append("file",file,file.name)
          axios.post(config.interestUpload,formdata,{headers:{'Content-Type': 'multipart/form-data'}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.form.img_url = response.data.data;
              _this.$message({
                message: '成功',
                type: 'success'
              });
            }
          })
        .catch(function (error) {
          console.log(error);
        });
        },
        beforeAvatarUpload(file) {
            const isImg = file.size / 1024 / 1024 < 2
            if (!isImg) {
                this.$message.error('上传头像图片大小不能超过 2MB!')
            }
                
            const isType = file.type === "image/png"
            const isType2 = file.type === "image/jpeg"

            if (!isType && !isType2) {
                this.$message.error('上传头像图片格式为png或jpg')
            }
            return (isType || isType2) && isImg
        },
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      },
      handleExceed(files, fileList) {
          this.$message.warning('当前限制选择 '+this.limit+' 个文件，本次选择了 '+files.length+' 个文件，共选择了'+ (files.length + fileList.length)+' 个文件');

      },
     onSubmit() {
      let _this = this;
      let postdata = this.form;

      if(postdata.name === ''){
        _this.$message.error('名称必填');
        return
      }
      axios.put(config.interest,postdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: '添加成功',
                type: 'success'
              });
              _this.goNav('/interestpage/interestlist');
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
  },
  created(){
      
  }
}
</script>
<style scoped>
.form{
    width: 500px;
    margin-left: 200px;
}
.el-icon-close:before{
  font-size: 24px;
}
</style>
